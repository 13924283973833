import * as XLSX from 'xlsx';

export const SPH_VACANCY_STATUS_MAP = {
  'Checked Out': 'checkout',
  'Stay Overs': 'stayover',
  Departures: 'checkout',
  Vacant: 'vacant',
  Blocked: 'blocked',
};

export const SPH_CLEAN_STATUS_MAP = {
  Clean: 'clean',
  Dirty: 'dirty',
};

const getAttendantSheetConfig = () => {
  const config = [];
  config[1] = 'number';
  config[5] = 'vacancy';
  config[11] = 'clean';
  return config;
};

const getAttendantSheetConfig2 = () => {
  const config = [];
  config[0] = 'number';
  config[4] = 'vacancy';
  config[10] = 'clean';
  return config;
};

// const getGuestSheetConfig = () => {
//   const config = [];
//   config[0] = 'name';
//   config[1] = 'reservationId';
//   config[3] = 'status';
//   config[24] = 'phoneNumber1';
//   config[25] = 'phoneNumber2';
//   config[36] = 'arrivalDatetime';
//   config[37] = 'departureDatetime';
//   config[39] = 'roomNumber';
//   return config;
// };

const getSPHSheetConfig = () => {
  const config = [];
  config[0] = 'number';
  config[2] = 'clean';
  config[3] = 'vacancy';
  config[4] = 'isBlocked';
  return config;
};

const getSPHMainSheetConfig = () => {
  const config = [];
  config[0] = 'number';
  config[2] = 'cleanStatus';
  config[4] = 'vacancyStatus';
  return config;
};

const getSPHBlockedSheetConfig = () => {
  const config = [];
  config[0] = 'number';
  config[2] = 'isBlocked';
  return config;
};

export const readXLSX = (file) => {
  const fileReader = new FileReader();

  fileReader.readAsArrayBuffer(file);

  let resolvePromise;
  let rejectPromise;

  const resultPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  fileReader.onload = async () => {
    try {
      // this is for SynXis Room Attendant Checklist
      const data = new Uint8Array(fileReader.result);
      const workbook = XLSX.read(data, { type: 'array', cellDates: true });
      let json = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1, {
        header: getAttendantSheetConfig(),
      });

      // this is also for SynXis Room Attendant Checklist
      // don't know which file format was uploaded, so try multiple configs
      if (json.length === 0) {
        json = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1, {
          header: getAttendantSheetConfig2(),
        });
      }

      // this is for SPH housekeeping data
      if (workbook.Sheets['Table001 (Page 1-3)']) {
        json = XLSX.utils.sheet_to_json(
          workbook.Sheets['Table001 (Page 1-3)'],
          {
            header: getSPHSheetConfig(),
          }
        );
      } else if (workbook.Sheets['Table001 (Page 1-4)']) {
        json = XLSX.utils.sheet_to_json(
          workbook.Sheets['Table001 (Page 1-4)'],
          {
            header: getSPHSheetConfig(),
          }
        );
      }

      // if the file is guest data for guest texting
      // if (
      //   workbook.Sheets.Sheet1.A1 &&
      //   workbook.Sheets.Sheet1.A1.v === 'Guest Name'
      // ) {
      //   json = XLSX.utils.sheet_to_json(workbook.Sheets.Sheet1, {
      //     header: getGuestSheetConfig(),
      //   });
      // }

      resolvePromise(json);
    } catch (e) {
      rejectPromise(e);
    }
  };

  return resultPromise;
};

const validateRoomNumber = (number) => {
  // Sometimes the column title gets combined with the actual room row, resulting in something like 'Room208' and 'StatusBlock'
  // We want to remove these column titles from the data
  let updatedNumber = number.toString().replace(/[^0-9]/g, '');
  while (updatedNumber.length > 0) {
    const temp = updatedNumber.substr(0, 3);
    updatedNumber = updatedNumber.slice(3);
    if (temp.length === 3) {
      number = parseInt(temp, 10);
    }
  }

  const hasRoomNumber = /\d/.test(number);
  const hasWhiteSpace = /\s/g.test(number);
  if (hasRoomNumber && !hasWhiteSpace) {
    return true;
  }
  return false;
};

export const readSPHMainSpreadSheet = (file) => {
  const config = getSPHMainSheetConfig();
  let resolvePromise;
  let rejectPromise;

  const resultPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  const convertSpreadSheet = (roomStatusArray) => {
    const roomStatusMap = {};
    roomStatusArray.forEach((roomStatus) => {
      const tempRoomStatus = { ...roomStatus };

      tempRoomStatus.vacancyStatus =
        SPH_VACANCY_STATUS_MAP[tempRoomStatus.vacancyStatus] ??
        SPH_VACANCY_STATUS_MAP.Vacant;
      tempRoomStatus.cleanStatus =
        SPH_CLEAN_STATUS_MAP[tempRoomStatus.cleanStatus] ??
        SPH_CLEAN_STATUS_MAP.Dirty;

      if (validateRoomNumber(roomStatus.number)) {
        roomStatusMap[roomStatus.number] = tempRoomStatus;
      }
    });

    return Object.keys(roomStatusMap).map((key) => roomStatusMap[key]);
  };

  // User uploaded file
  if (typeof file === 'object') {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      try {
        const data = new Uint8Array(fileReader.result);

        const workbook = XLSX.read(data, { type: 'array', cellDates: true });
        const roomStatusArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            header: config,
          }
        );

        // The first two rows of the spreadsheet aren't used
        roomStatusArray.splice(0, 2);

        resolvePromise(convertSpreadSheet(roomStatusArray));
      } catch (e) {
        rejectPromise(e);
      }
    };
  }
  // Manual file path, used for testing
  else {
    try {
      const workbook = XLSX.readFile(file);
      const roomStatusArray = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: config,
        }
      );

      // The first two rows of the spreadsheet aren't used
      roomStatusArray.splice(0, 2);

      resolvePromise(convertSpreadSheet(roomStatusArray));
    } catch (e) {
      rejectPromise(e);
    }
  }

  return resultPromise;
};

export const readSPHBlockedSpreadSheet = (file) => {
  const config = getSPHBlockedSheetConfig();

  let resolvePromise;
  let rejectPromise;

  const resultPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  const convertSpreadSheet = (roomStatusArray) => {
    const roomStatusMap = {};
    roomStatusArray.forEach((roomStatus) => {
      const tempRoomStatus = { ...roomStatus };

      tempRoomStatus.vacancyStatus =
        tempRoomStatus.isBlocked && SPH_VACANCY_STATUS_MAP.Blocked;
      delete tempRoomStatus.isBlocked;

      if (
        validateRoomNumber(roomStatus.number) &&
        tempRoomStatus.vacancyStatus
      ) {
        roomStatusMap[roomStatus.number] = tempRoomStatus;
      }
    });

    return Object.keys(roomStatusMap).map((key) => roomStatusMap[key]);
  };

  // User uploaded file
  if (typeof file === 'object') {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async () => {
      try {
        const data = new Uint8Array(fileReader.result);

        const workbook = XLSX.read(data, { type: 'array', cellDates: true });
        const roomStatusArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            header: config,
          }
        );

        // The first two rows of the spreadsheet aren't used
        roomStatusArray.splice(0, 2);

        resolvePromise(convertSpreadSheet(roomStatusArray));
      } catch (e) {
        rejectPromise(e);
      }
    };
  }
  // Manual file path, used for testing
  else {
    try {
      const workbook = XLSX.readFile(file);
      const roomStatusArray = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: config,
        }
      );

      // The first two rows of the spreadsheet aren't used
      roomStatusArray.splice(0, 2);

      resolvePromise(convertSpreadSheet(roomStatusArray));
    } catch (e) {
      rejectPromise(e);
    }
  }

  return resultPromise;
};

export const convertSPHSpreadSheetToArray = (file) => {
  let resolvePromise;
  let rejectPromise;

  const resultPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  const fileReader = new FileReader();

  fileReader.readAsArrayBuffer(file);
  fileReader.onload = async () => {
    try {
      const data = new Uint8Array(fileReader.result);

      const workbook = XLSX.read(data, { type: 'array', cellDates: true });
      const rows = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );

      let toReturn = [];
      // This is the main spread sheet
      if (rows[1][3] === 'OCCUPANCY\r\nSTATUS') {
        toReturn = await readSPHMainSpreadSheet(file);
      } else if (rows[1][2] === 'OOO/OOI') {
        toReturn = await readSPHBlockedSpreadSheet(file);
      } else {
        // Log an error
      }

      resolvePromise(toReturn);
    } catch (e) {
      rejectPromise(e);
    }
  };

  return resultPromise;
};
