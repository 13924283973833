import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import UploadIcon from '@material-ui/icons/UploadFile';
import {
  IconButton,
  Tooltip,
  Toolbar,
  AppBar,
  Avatar,
  Typography,
} from '@material-ui/core';

import { request, uploadSPHPDF } from 'src/utils/LodgebookAPIClient';
import { useStore } from 'src/context/StoreContext';
import { convertSPHSpreadSheetToArray } from 'src/utils/XLSXFileReader';
import PrintRoomAssignments from 'src/screens/Dashboard/PrintRoomAssignments/PrintRoomAssignments';
import { ReactComponent as LodgebookLogo } from 'src/assets/images/lodgebook_logo.svg';
import useBreakpoints from 'src/utils/useBreakpoints';
import NavigationBarMenu from './components/NavigationBarMenu';

const Header = styled(AppBar)`
  height: 65px;
  z-index: 0;
  box-shadow: none;
`;

const UserInitials = styled(Avatar)`
  background-color: #666666;
  color: #fff;
  cursor: pointer;
`;

const NavBarEndContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  max-width: 500px;
`;

const TopNav = styled(Toolbar)`
  justify-content: space-between;
`;

const HotelInfo = styled(Typography)`
  color: #666666;
  font-weight: bold;
`;

export const UPLOAD_SYNXIS_PDF_TEXT = 'UPLOAD SYNXIS PDF DATA';
export const UPLOAD_SYNXIS_XSLX_TEXT = 'UPLOAD SYNXIS SPREADSHEET DATA';

const NavigationBar = observer(() => {
  const { userStore, hotelStore, roomStore, taskStore } = useStore();
  const { fetchAllRooms, updateRooms } = roomStore;
  const { selectedHotelId } = hotelStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);
  const [roomAssignments, setRoomAssignments] = useState(null);
  const { isSmallScreen } = useBreakpoints();

  useEffect(() => {
    if (selectedHotelId) {
      (async function fetchRoomAssignments() {
        const roomAssignmentsRequest = await request(
          `/room_assignments?hotel_id=${selectedHotelId}`,
          'GET'
        );
        setRoomAssignments(roomAssignmentsRequest.roomAssignments);
      })();
    }
  }, [selectedHotelId]);

  let userInitials = '?';
  const { authenticatedUser } = userStore;
  if (authenticatedUser) {
    userInitials = `${authenticatedUser.firstName.charAt(
      0
    )}${authenticatedUser.lastName.charAt(0)}`;
  }

  const uploadPdf = async ({ target }) => {
    await uploadSPHPDF(target.files[0], selectedHotelId);
    await fetchAllRooms(selectedHotelId);
  };

  const uploadSpreadsheet = async ({ target }) => {
    const payload = await convertSPHSpreadSheetToArray(target.files[0]);
    updateRooms(payload, selectedHotelId);
  };

  return (
    <>
      <Header color="secondary">
        <TopNav>
          {isSmallScreen ? (
            <HotelInfo variant="h6">{hotelStore.name}</HotelInfo>
          ) : (
            <LodgebookLogo />
          )}
          <NavBarEndContainer>
            <Tooltip title={UPLOAD_SYNXIS_PDF_TEXT}>
              <IconButton
                color="primary"
                aria-label={UPLOAD_SYNXIS_PDF_TEXT}
                component="label"
              >
                <UploadIcon />
                <input
                  data-testid={UPLOAD_SYNXIS_PDF_TEXT}
                  accept="file/*"
                  id="contained-button-file"
                  multiple
                  onChange={uploadPdf}
                  type="file"
                  hidden
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={UPLOAD_SYNXIS_XSLX_TEXT}>
              <IconButton
                color="primary"
                aria-label={UPLOAD_SYNXIS_XSLX_TEXT}
                component="label"
              >
                <UploadIcon />
                <input
                  data-testid={UPLOAD_SYNXIS_XSLX_TEXT}
                  accept="file/*"
                  id="contained-button-file"
                  multiple
                  onChange={uploadSpreadsheet}
                  type="file"
                  hidden
                />
              </IconButton>
            </Tooltip>

            <PrintRoomAssignments
              userStore={userStore}
              hotelStore={hotelStore}
              taskStore={taskStore}
              roomStore={roomStore}
              roomAssignments={roomAssignments}
            />

            <UserInitials
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Typography variant="caption">{userInitials}</Typography>
            </UserInitials>
          </NavBarEndContainer>
        </TopNav>
      </Header>
      <NavigationBarMenu
        menuVisible={menuVisible}
        handleClickAway={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
      />
    </>
  );
});

NavigationBar.propTypes = {};

export default NavigationBar;
